import React from 'react';

import css from './TermsOfServicePage.module.css';

const TermsContentBrazil = props => {
  return (
    <div>
      <p style={{ textAlign: 'center' }}>
        <strong>
          <span style={{ fontSize: '10.5pt' }}>
            TERMOS E CONDIÇÕES DE USO DO MARKETPLACE RESPARE
          </span>
        </strong>
      </p>
      <p style={{ textAlign: 'justify' }}>
        <strong>
          <span style={{ fontSize: '10.5pt' }}>
            HUSQVARNA DO BRASIL INDÚSTRIA E COMÉRCIO DE PRODUTOS PARA FLORESTA E JARDIM LTDA.&nbsp;
          </span>
        </strong>
        <span style={{ fontSize: '10.5pt' }}>(“</span>
        <u>
          <span style={{ fontSize: '10.5pt' }}>HUSQVARNA</span>
        </u>
        <span style={{ fontSize: '10.5pt' }}>
          ”), com endereço na Cidade de São Carlos, Estado de São Paulo, na Rua Coronel José Augusto
          de Oliveira Salles, S/N, CEP&nbsp;13570-820, inscrita no CNPJ/ME sob
          nº&nbsp;04.098.470/0001-90, um membro do Grupo (“
        </span>
        <strong>
          <span style={{ fontSize: '10.5pt' }}>Husqvarna”)</span>
        </strong>
        <span style={{ fontSize: '10.5pt' }}>
          &nbsp; única e exclusiva proprietária do marketplace denominado Respare, hospedado no
          domínio https://respare-br.husqvarna.com/ (o “
        </span>
        <u>
          <span style={{ fontSize: '10.5pt' }}>MARKETPLACE</span>
        </u>
        <span style={{ fontSize: '10.5pt' }}>
          ”), estabelece estes TERMOS E CONDIÇÕES DE USO DO MARKETPLACE RESPARE, os quais regem,
          exclusivamente, as negociações de compra e venda realizadas pelos PARCEIROS através do
          MARKETPLACE e qualquer uso que os PARCEIROS fizerem do MARKETPLACE, conforme as cláusulas
          e condições abaixo descritas, com exclusão de qualquer outro documento, termos e/ou
          condições que possam adicionar, alterar ou diferir deste instrumento.&nbsp;
        </span>
      </p>
      <p style={{ textAlign: 'justify' }}>
        <span style={{ fontSize: '10.5pt' }}>
          O PARCEIRO deve ler com atenção os termos abaixo antes de acessar ou usar o MARKETPLACE,
          pois o seu acesso ou uso implica concordância integral a todas as condições destes TERMOS
          DE USO, ao passo que, da mesma forma, o PARCEIRO somente poderá se utilizar do MARKETPLACE
          mediante expressa concordância a estes TERMOS DE USO.&nbsp;
        </span>
      </p>
      <ol className={css.normalList}>
        <li>
          <strong style={{ listStyleType: 'decimal', fontSize: '10.5pt' }}>DEFINIÇÕES</strong>

          <ol className={css.normalList}>
            <li style={{ listStyleType: 'decimal', fontSize: '10.5pt' }}>
              As palavras grafadas neste instrumento com letras maiúsculas terão o significado que a
              elas é atribuído de acordo com o estabelecido abaixo:
              <ol style={{ listStyleType: 'lower-roman', fontSize: '10.5pt' }}>
                <li style={{ fontSize: '10.5pt' }}>
                  <span style={{ fontSize: '10.5pt' }}>
                    HUSQVARNA: é a pessoa jurídica proprietária do domínio
                    &nbsp;https://respare-br.husqvarna.com/ &nbsp;e responsável pela gestão e
                    administração do MARKETPLACE.
                  </span>
                </li>
                <li style={{ listStyleType: 'lower-roman', fontSize: '10.5pt' }}>
                  <span style={{ fontSize: '10.5pt' }}>
                    MARKETPLACE: é o site de internet denominado Respare, registrado no Núcleo de
                    Informação e Coordenação sob o domínio https://respare-br.husqvarna.com/, com
                    todos os recursos e ferramentas relacionadas a este.
                  </span>
                </li>
                <li style={{ listStyleType: 'lower-roman', fontSize: '10.5pt' }}>
                  <span style={{ fontSize: '10.5pt' }}>
                    PARCEIRO: é a revenda credenciada pela Husqvarna que terá acesso aos serviços
                    para cadastro dos PRODUTOS no MARKETPLACE e realização de negociações de venda e
                    compra dos PRODUTOS.
                  </span>
                </li>
                <li style={{ listStyleType: 'lower-roman', fontSize: '10.5pt' }}>
                  <span style={{ fontSize: '10.5pt' }}>
                    PRODUTO(S): peças novas não utilizadas de reposição obsoletas (fora de linha)
                    para equipamentos e máquinas produzidos pela HUSQVARNA dentro do território
                    brasileiro.
                  </span>
                </li>
                <li style={{ listStyleType: 'lower-roman', fontSize: '10.5pt' }}>
                  <span style={{ fontSize: '10.5pt' }}>
                    COOKIES: pequenos arquivos de texto armazenados nos navegadores. Este recurso
                    possibilita a identificação de PARCEIROS e o direcionamento de documentos de
                    acordo com parâmetros pré-estabelecidos.
                  </span>
                </li>
                <li style={{ listStyleType: 'lower-roman', fontSize: '10.5pt' }}>
                  <span style={{ fontSize: '10.5pt' }}>
                    LINK: significa um acesso eletrônico, seja por meio de imagens ou palavras, que
                    permite a conexão a outras telas de um mesmo site, ou, ainda, de outros sites.
                  </span>
                </li>
                <li style={{ listStyleType: 'lower-roman', fontSize: '10.5pt' }}>
                  <span style={{ fontSize: '10.5pt' }}>
                    SPAM: são e-mails enviados a um grupo de PARCEIROS sem a sua devida permissão.
                  </span>
                </li>
                <li style={{ listStyleType: 'lower-roman', fontSize: '10.5pt' }}>
                  <span style={{ fontSize: '10.5pt' }}>
                    CONTEÚDO: inclui texto, programas, scripts, gráficos, fotos, sons, imagens,
                    músicas, vídeos, combinações audiovisuais, animações, recursos interativos e
                    outros materiais que o(s) PARCEIRO têm acesso.
                  </span>
                </li>
                <li style={{ listStyleType: 'lower-roman', fontSize: '10.5pt' }}>
                  <span style={{ fontSize: '10.5pt' }}>
                    TERMOS DE USO: corresponde aos presentes TERMOS E CONDIÇÕES DE USO DO
                    MARKETPLACE RESPARE, que é o contrato que guiará a relação entre PARCEIROS entre
                    si e HUSQVARNA.
                  </span>
                </li>
              </ol>
            </li>
          </ol>
        </li>
        <li style={{ listStyleType: 'decimal', fontSize: '10.5pt' }}>
          <strong>
            <span style={{ fontSize: '10.5pt' }}>RESPONSABILIDADES</span>
          </strong>

          <ol className={css.normalList}>
            <li style={{ listStyleType: 'decimal', fontSize: '10.5pt' }}>
              <span style={{ fontSize: '10.5pt' }}>
                Os PRODUTOS são vendidos por PARCEIROS independentes, sendo responsabilidade da
                HUSQVARNA, exclusivamente, a prestação de serviços de disponibilização da plataforma
                para intermediação dos processos de venda e compra entre os PARCEIROS, ou seja,
                apenas disponibilizando o meio para que as vendas entre eles possam ocorrer, não
                fazendo parte, portanto, da relação contratual de compra e venda estabelecida entre
                os PARCEIROS. Desta forma, a HUSQVARNA não assume qualquer responsabilidade:
              </span>

              <ol>
                <li style={{ listStyleType: 'lower-roman', fontSize: '10.5pt' }}>
                  <span style={{ fontSize: '10.5pt' }}>
                    Que advenha das relações existentes entre os PARCEIROS, sejam elas diretas ou
                    indiretas;
                  </span>
                </li>
                <li style={{ listStyleType: 'lower-roman', fontSize: '10.5pt' }}>
                  <span style={{ fontSize: '10.5pt' }}>
                    Por negociações efetuadas entre os PARCEIROS;
                  </span>
                </li>
                <li style={{ listStyleType: 'lower-roman', fontSize: '10.5pt' }}>
                  <span style={{ fontSize: '10.5pt' }}>
                    Pela entrega de PRODUTOS que são de inteira responsabilidade do PARCEIRO
                    contratado;
                  </span>
                </li>
                <li style={{ listStyleType: 'lower-roman', fontSize: '10.5pt' }}>
                  <span style={{ fontSize: '10.5pt' }}>
                    Pela quantidade dos PRODUTOS anunciados pelo PARCEIRO no MARKETPLACE, sendo do
                    PARCEIRO a total e exclusiva responsabilidade.
                  </span>
                </li>
              </ol>
            </li>
            <li style={{ listStyleType: 'decimal', fontSize: '10.5pt' }}>
              <span style={{ fontSize: '10.5pt' }}>
                A HUSQVARNA envidará seus melhores esforços para manter o MARKETPLACE sempre
                atualizado, completo e preciso, mas não garante que o mesmo funcionará de forma
                ininterrupta e sem atrasos, por se tratar de um serviço de tecnologia.
              </span>
            </li>
            <li style={{ listStyleType: 'decimal', fontSize: '10.5pt' }}>
              <span style={{ fontSize: '10.5pt' }}>
                A HUSQVARNA envidará seus melhores esforços para corrigir eventuais erros de
                funcionamento do MARKETPLACE, comunicando, nesse mesmo MARKETPLACE, a sua
                indisponibilidade e previsão de retorno após as necessárias e imprescindíveis
                correções e/ou manutenção.
              </span>
            </li>
            <li style={{ listStyleType: 'decimal', fontSize: '10.5pt' }}>
              <span style={{ fontSize: '10.5pt' }}>
                A HUSQVARNA não se responsabiliza por qualquer dano, prejuízo ou perda sofridos pelo
                PARCEIRO em razão de falhas na internet, no sistema ou no servidor utilizado pelo
                PARCEIRO, decorrentes de condutas de terceiros, caso fortuito ou força maior. O
                acesso ao MARKETPLACE depende da funcionalidade simultânea de diversos fatores,
                alguns alheios ao controle da HUSQVARNA. Assim, a HUSQVARNA fica isenta de qualquer
                responsabilidade por falhas decorrentes de qualquer impossibilidade de acesso ao
                MARKETPLACE em virtude de motivos que fogem ao seu controle e diligência.
              </span>
            </li>
            <li style={{ listStyleType: 'decimal', fontSize: '10.5pt' }}>
              <span style={{ fontSize: '10.5pt' }}>
                A HUSQVARNA não garante, sob nenhuma hipótese, que o site em que hospeda o
                MARKETPLACE esteja livre de possíveis falhas, interrupções, problemas, e outros
                fatores, não se responsabilizando, também, pela qualidade e segurança da rede
                utilizada pelo PARCEIRO para acesso ao MARKETPLACE, vez que esta é mantida por
                terceiros, e, portanto, foge do seu controle, diligência e responsabilidade.
              </span>
            </li>
            <li style={{ listStyleType: 'decimal', fontSize: '10.5pt' }}>
              <span style={{ fontSize: '10.5pt' }}>
                A HUSQVARNA também não será responsável por qualquer vírus que possa atacar o
                equipamento do PARCEIRO em decorrência do acesso, utilização ou navegação na
                internet ou como consequência da transferência de dados, arquivos, imagens, textos,
                vídeos ou áudio.
              </span>
            </li>
            <li style={{ listStyleType: 'decimal', fontSize: '10.5pt' }}>
              <span style={{ fontSize: '10.5pt' }}>
                A utilização pelo PARCEIRO de ferramentas e tecnologia desatualizadas, não
                consideradas seguras, dar-se-á por conta e risco do próprio PARCEIRO, ficando a
                HUSQVARNA isenta de qualquer responsabilidade por danos, de qualquer natureza,
                suportados pelo PARCEIRO.
              </span>
            </li>
            <li style={{ listStyleType: 'decimal', fontSize: '10.5pt' }}>
              <span style={{ fontSize: '10.5pt' }}>
                A HUSQVARNA, bem como seus funcionários, agentes, colaboradores, representantes e
                procuradores, devem ser eximidos de toda e qualquer responsabilidade decorrente de
                qualquer tipo de reclamação ou ação legal contra um ou mais PARCEIROS, sejam elas
                promovidas por um ou mais terceiros.
              </span>
            </li>
            <li style={{ listStyleType: 'decimal', fontSize: '10.5pt' }}>
              <span style={{ fontSize: '10.5pt' }}>
                A HUSQVARNA não se responsabiliza pelas obrigações tributárias que recaiam sobre as
                atividades entre os PARCEIROS no MARKETPLACE. Assim, ao adquirir algum PRODUTO, o
                PARCEIRO deverá exigir documento fiscal do outro PARCEIRO, a menos que este esteja
                legalmente dispensado de fornecê-lo.
              </span>
            </li>
            <li style={{ listStyleType: 'decimal', fontSize: '10.5pt' }}>
              <span style={{ fontSize: '10.5pt' }}>
                Uma oferta de PRODUTO pode ser excluída do MARKETPLACE quando os PRODUTOS em estoque
                estiverem esgotados e não for possível ao PARCEIRO efetuar a reposição, e a
                HUSQVARNA não será responsabilizado por isso. A HUSQVARNA envidará seus melhores
                esforços para que o MARKETPLACE possa informar quando um PRODUTO estiver perto de se
                esgotar, mas não presta qualquer garantia nesse sentido.
              </span>
            </li>
            <li style={{ listStyleType: 'decimal', fontSize: '10.5pt' }}>
              <span style={{ fontSize: '10.5pt' }}>
                O PARCEIRO é o único e exclusivo responsável por verificar e conferir a dimensão
                física dos PRODUTOS que pretende adquirir por meio do MARKETPLACE, devendo se
                certificar de que os PRODUTOS estão de acordo com os limites espaciais do local de
                entrega. A HUSQVARNA não se responsabiliza pela montagem ou desmontagem do PRODUTO,
                guincho, transporte pelo elevador e/ou escadas, por portas e/ou janelas, sendo de
                inteira responsabilidade do PARCEIRO informar tais condições ao outro PARCEIRO
                quando das negociações de venda e compra dos PRODUTOS.
              </span>
            </li>
            <li style={{ listStyleType: 'decimal', fontSize: '10.5pt' }}>
              <span style={{ fontSize: '10.5pt' }}>
                As informações, dados e características dispostas sobre cada PRODUTO, bem como
                fotografias ou vídeos relacionados ao PRODUTO - incluindo nomes comerciais, marcas,
                símbolos distintivos de qualquer classe - são expostos somente a título de
                orientação e ilustração.
              </span>
            </li>
            <li style={{ listStyleType: 'decimal', fontSize: '10.5pt' }}>
              <span style={{ fontSize: '10.5pt' }}>
                A HUSQVARNA não se responsabiliza por qualquer dano, de qualquer natureza, causado a
                terceiros em decorrência de conduta do PARCEIRO imprópria, ofensiva ou contrária à
                legislação vigente e às orientações de uso e manuseio dos PRODUTOS.
              </span>
            </li>
            <li style={{ listStyleType: 'decimal', fontSize: '10.5pt' }}>
              <span style={{ fontSize: '10.5pt' }}>
                O MARKETPLACE pode conter links a sites não operados pela HUSQVARNA. A HUSQVARNA
                fornece os links para a comodidade do PARCEIRO, mas não analisa, controla ou
                monitora os materiais de qualquer outro site. A HUSQVARNA não será responsável pelo
                desempenho desses sites ou pelas negociações comerciais que o PARCEIRO fizer com
                eles. A utilização de outros sites correrá por conta e risco exclusivos do PARCEIRO,
                e estará sujeita aos termos de uso desses sites, incluindo as políticas de
                privacidade desses sites.
              </span>
            </li>
          </ol>
        </li>
        <li style={{ listStyleType: 'decimal', fontSize: '10.5pt' }}>
          <strong>
            <span style={{ fontSize: '10.5pt' }}>CADASTRO NO MARKETPLACE</span>
          </strong>

          <ol className={css.normalList}>
            <li style={{ listStyleType: 'decimal', fontSize: '10.5pt' }}>
              <span style={{ fontSize: '10.5pt' }}>
                Para fins de cadastro e utilização do MARKETPLACE, os PARCEIROS elegíveis pela
                HUSQVARNA receberão o link de acesso e código exclusivo para fins de proceder com
                seu registro na Plataforma, momento em que será necessário nos fornecer alguns de
                seus dados, conforme segue, razão social, nome fantasia, número do CNPJ ativo na
                Receita Federal, e-mail, endereço completo, telefone e Código do PARCEIRO junto a
                HUSQVARNA.
              </span>
            </li>
            <li style={{ listStyleType: 'decimal', fontSize: '10.5pt' }}>
              <span style={{ fontSize: '10.5pt' }}>
                Ao se cadastrar, o PARCEIRO estará concedendo o seu consentimento de forma livre,
                inequívoca e expressa, para que a HUSQVARNA se utilize de seus dados de acordo com a
                Declaração de Privacidade do MARKETPLACE.
              </span>
            </li>
            <li style={{ listStyleType: 'decimal', fontSize: '10.5pt' }}>
              <span style={{ fontSize: '10.5pt' }}>
                O PARCEIRO somente poderá fornecer informações corretas, atualizadas e verdadeiras,
                respondendo integralmente pela veracidade e autenticidade das mesmas. O PARCEIRO se
                compromete ainda a manter suas informações sempre atualizadas. A HUSQVARNA não faz
                qualquer verificação quanto à autenticidade dos dados cadastrais. Assim, o PARCEIRO
                deverá arcar isoladamente com qualquer declaração falsa ou omissa que possa gerar
                prejuízos à HUSQVARNA ou a terceiros.
              </span>
            </li>
            <li style={{ listStyleType: 'decimal', fontSize: '10.5pt' }}>
              <span style={{ fontSize: '10.5pt' }}>
                A HUSQVARNA e o PARCEIRO reservam para si o direito de utilizar todos os meios
                válidos e possíveis para identificar os demais PARCEIROS, bem como de solicitar
                dados adicionais e documentos que sejam necessários a fim de conferir os dados
                informados. Caso o PARCEIRO se furte ou se negue a enviar os documentos requeridos,
                a HUSQVARNA poderá suspender temporariamente ou definitivamente sua conta, sem
                prejuízo de outras medidas que entender necessárias, oportunas e cabíveis.
              </span>
            </li>
            <li style={{ listStyleType: 'decimal', fontSize: '10.5pt' }}>
              <span style={{ fontSize: '10.5pt' }}>
                As credenciais eletrônicas e senhas secretas do PARCEIRO são individuais e
                intransferíveis. Portanto, o PARCEIRO será o único e exclusivo responsável por sua
                conta de acesso ao MARKETPLACE, bem como pelas operações realizadas, uma vez que o
                acesso à conta somente é possível mediante a utilização de uma senha, que é criada
                pelo próprio PARCEIRO e deverá ser de conhecimento e propriedade exclusiva do
                PARCEIRO. &nbsp;Portanto, o PARCEIRO assume neste ato integral responsabilidade pela
                guarda e pelo sigilo de seu&nbsp;
              </span>
              <em>
                <span style={{ fontSize: '10.5pt' }}>login</span>
              </em>
              <span style={{ fontSize: '10.5pt' }}>
                &nbsp;e senha de acesso, bem como pelo uso indevido de seu cadastro por terceiros. O
                PARCEIRO compromete-se a notificar a HUSQVARNA imediatamente, e por meio seguro,
                caso tenha conhecimento de qualquer uso ou acesso não autorizado de sua conta por
                terceiros.&nbsp;
              </span>
            </li>
          </ol>
        </li>
        <li style={{ listStyleType: 'decimal', fontSize: '10.5pt' }}>
          <strong>
            <span style={{ fontSize: '10.5pt' }}>COMUNICAÇÃO</span>
          </strong>

          <ol className={css.normalList}>
            <li style={{ listStyleType: 'decimal', fontSize: '10.5pt' }}>
              <span style={{ fontSize: '10.5pt' }}>
                Por meio da criação de sua conta e aceite desses TERMOS DE USO, fica autorizado a
                HUSQVARNA e aos demais PARCEIROS cadastrados utilizar, além das ferramentas do
                próprio MARKETPLACE, seu número de telefone (mediante envio de SMS) e endereço de
                e-mail como meio de comunicação para envio de avisos, como entrega de PRODUTO,
                confirmação de pagamento e outras informações necessárias e úteis ao PARCEIRO.
              </span>
            </li>
          </ol>
        </li>

        <li style={{ listStyleType: 'decimal', fontSize: '10.5pt' }}>
          <strong>
            <span style={{ fontSize: '10.5pt' }}>OBRIGAÇÕES DO PARCEIRO</span>
          </strong>

          <ol className={css.normalList}>
            <li style={{ listStyleType: 'decimal', fontSize: '10.5pt' }}>
              <span style={{ fontSize: '10.5pt' }}>
                O PARCEIRO se obriga a acessar o MARKETPLACE da forma prevista nestes TERMOS DE USO
                e demais políticas dispostas no MARKETPLACE.&nbsp;
              </span>
            </li>
            <li style={{ listStyleType: 'decimal', fontSize: '10.5pt' }}>
              <span style={{ fontSize: '10.5pt' }}>
                O PARCEIRO também se compromete, ao utilizar dos serviços do MARKETPLACE, a não
                cometer atos que tenham por finalidade:
              </span>

              <ol>
                <li style={{ listStyleType: 'lower-roman', fontSize: '10.5pt' }}>
                  <span style={{ fontSize: '10.5pt' }}>
                    interromper serviço, servidores, ou rede de computadores por meio de qualquer
                    método ilícito;
                  </span>
                </li>
                <li style={{ listStyleType: 'lower-roman', fontSize: '10.5pt' }}>
                  <span style={{ fontSize: '10.5pt' }}>
                    obter acesso não autorizado a outro computador, servidor ou rede;
                  </span>
                </li>
                <li style={{ listStyleType: 'lower-roman', fontSize: '10.5pt' }}>
                  <span style={{ fontSize: '10.5pt' }}>
                    burlar qualquer sistema de autenticação ou de segurança;&nbsp;
                  </span>
                </li>
                <li style={{ listStyleType: 'lower-roman', fontSize: '10.5pt' }}>
                  <span style={{ fontSize: '10.5pt' }}>
                    acessar informações confidenciais, de qualquer natureza, como nome de PARCEIRO
                    ou senhas de acesso de outro PARCEIRO do MARKETPLACE que esteja vulnerável; e
                  </span>
                </li>
                <li style={{ listStyleType: 'lower-roman', fontSize: '10.5pt' }}>
                  <span style={{ fontSize: '10.5pt' }}>
                    acessar informações financeiras, ou que possam causar prejuízos a qualquer
                    pessoa, como, por exemplo, números de cartões de crédito, contas bancárias,
                    entre outros.
                  </span>
                </li>
              </ol>
            </li>
            <li style={{ listStyleType: 'decimal', fontSize: '10.5pt' }}>
              <span style={{ fontSize: '10.5pt' }}>
                O PARCEIRO compromete-se a utilizar o MARKETPLACE e seu conteúdo, ou seja, os
                textos, fotografias de PRODUTOS, imagens e demais conteúdos audiovisuais ou sonoros,
                de acordo com todo o ordenamento jurídico, os bons costumes, os presentes TERMOS DE
                USO e demais regras existentes no MARKETPLACE.
              </span>
            </li>
            <li style={{ listStyleType: 'decimal', fontSize: '10.5pt' }}>
              <span style={{ fontSize: '10.5pt' }}>
                Caso o PARCEIRO não cumpra com o previsto nestes TERMOS DE USO, a HUSQVARNA reserva
                a si o direito de suspender temporariamente, bloquear ou excluir a conta do
                PARCEIRO, sem necessidade de notificação prévia, sem prejuízo das demais medidas que
                entender cabíveis e necessárias, como comunicação às autoridades competentes.
              </span>
            </li>
          </ol>
        </li>
        <li style={{ listStyleType: 'decimal', fontSize: '10.5pt' }}>
          <strong>
            <span style={{ fontSize: '10.5pt' }}>PROPRIEDADE INTELECTUAL</span>
          </strong>

          <ol className={css.normalList}>
            <li style={{ listStyleType: 'decimal', fontSize: '10.5pt' }}>
              <span style={{ fontSize: '10.5pt' }}>
                Todo o conteúdo do MARKETPLACE é de propriedade única e exclusiva da HUSQVARNA, , o
                que inclui, sem se limitar, imagens, vídeos, sons, formatação gráfica e editorial,
                textos, fotos, marcas, patentes e demais conteúdos. O uso indevido e/ou reprodução
                parcial ou total do conteúdo é proibido.
              </span>
            </li>
            <li style={{ listStyleType: 'decimal', fontSize: '10.5pt' }}>
              <span style={{ fontSize: '10.5pt' }}>
                São vedados quaisquer atos ou contribuições tendentes à descompilação, engenharia
                reversa, modificação das características, ampliação, alteração, mesclagem ou
                incorporação em quaisquer outros programas ou sistemas do MARKETPLACE. Toda e
                qualquer forma de reprodução do MARKETPLACE, total ou parcial, permanente,
                temporária ou provisória, de forma gratuita ou onerosa, sob quaisquer modalidades,
                formas ou títulos, é expressamente vedada.
              </span>
            </li>
            <li style={{ listStyleType: 'decimal', fontSize: '10.5pt' }}>
              <span style={{ fontSize: '10.5pt' }}>
                O PARCEIRO está ciente de que qualquer violação à propriedade intelectual do
                MARKETPLACE, conforme disposto nos itens 6.1 e 6.2 acima, constitui grave infração a
                estes TERMOS DE USO, podendo a HUSQVARNA, a seu exclusivo critério, suspender,
                bloquear ou excluir a conta do PARCEIRO, sem prejuízo da obrigação do PARCEIRO de
                indenizar à HUSQVARNA e terceiros pelas perdas e danos sofridos.
              </span>
            </li>
          </ol>
        </li>
        <li style={{ listStyleType: 'decimal', fontSize: '10.5pt' }}>
          <strong>
            <span style={{ fontSize: '10.5pt' }}>
              COMPRAS, CONDIÇÕES, FORMA DE PAGAMENTO E ENTREGA
            </span>
          </strong>

          <ol className={css.normalList}>
            <li style={{ listStyleType: 'decimal', fontSize: '10.5pt' }}>
              <span style={{ fontSize: '10.5pt' }}>
                O PARCEIRO compromete-se a realizar o pagamento a partir do momento em que concluir
                o pedido, nos termos e nas condições selecionados quando da sua efetivação durante
                as negociações com o outro PARCEIRO.&nbsp;
              </span>
            </li>
            <li style={{ listStyleType: 'decimal', fontSize: '10.5pt' }}>
              <span style={{ fontSize: '10.5pt' }}>
                Ao preço que conste nos anúncios, para cada um dos PRODUTOS oferecidos, serão
                somadas e informadas as eventuais tarifas correspondentes aos gastos de envio
                (frete) pertinentes. Em qualquer caso, tais tarifas serão comunicadas de forma
                expressa e prévia pelo PARCEIRO vendedor ao PARCEIRO comprador, antes da finalização
                da compra.
              </span>
            </li>
            <li style={{ listStyleType: 'decimal', fontSize: '10.5pt' }}>
              <span style={{ fontSize: '10.5pt' }}>
                O valor do frete para as compras efetuadas pelo PARCEIRO varia conforme a
                localidade, peso, dimensão e quantidade dos PRODUTOS.
              </span>
            </li>
            <li style={{ listStyleType: 'decimal', fontSize: '10.5pt' }}>
              <span style={{ fontSize: '10.5pt' }}>
                Se o pedido for composto por itens de PARCEIROS diversos, haverá mais de uma
                cobrança de frete. Em caso de algum item com isenção de frete, os demais serão
                cobrados normalmente.
              </span>
            </li>
            <li style={{ listStyleType: 'decimal', fontSize: '10.5pt' }}>
              <span style={{ fontSize: '10.5pt' }}>
                O PARCEIRO comprador deverá pagar o valor correspondente a seu pedido por meio das
                formas de pagamento oferecidas pelo PARCEIRO vendedor.
              </span>
              <span style={{ fontSize: '10.5pt' }}>&nbsp;</span>
            </li>
            <li style={{ listStyleType: 'decimal', fontSize: '10.5pt' }}>
              <span style={{ fontSize: '10.5pt' }}>
                O prazo máximo de entrega será informado no ato da compra pelo PARCEIRO vendedor,
                conforme o tipo de entrega solicitada pelo PARCEIRO comprador, sendo que sua
                contagem, a princípio, terá início somente após a aprovação de pagamento, que poderá
                variar de acordo com a forma de pagamento escolhida.
              </span>
            </li>
            <li style={{ listStyleType: 'decimal', fontSize: '10.5pt' }}>
              <span style={{ fontSize: '10.5pt' }}>
                As formas de pagamento variam de acordo com a disponibilidade apresentada pelo
                PARCEIRO vendedor no momento da negociação.
              </span>

              <ol className={css.normalList}>
                <li style={{ listStyleType: 'decimal', fontSize: '10.5pt' }}>
                  <span style={{ fontSize: '10.5pt' }}>
                    Para pagamentos com cartão de crédito, o(s) pedido(s) estará(ão) sujeito(s) à
                    aprovação da administradora do cartão de crédito eleito pelo PARCEIRO vendedor.
                    As informações contidas no cadastro são passíveis de confirmação, que poderão
                    ser solicitadas pelo PARCEIRO, por e-mail.
                  </span>
                </li>
                <li style={{ listStyleType: 'decimal', fontSize: '10.5pt' }}>
                  <span style={{ fontSize: '10.5pt' }}>
                    Para compras pagas por meio de boleto bancário, o prazo para entrega é
                    considerado a partir da confirmação do pagamento pela instituição financeira. A
                    confirmação pode ser realizada em até 3 (três) dias úteis, a partir do
                    pagamento, sendo que este prazo é estimado e pode variar de acordo com as
                    informações do PARCEIRO vendedor.
                  </span>
                </li>
              </ol>
            </li>
          </ol>
        </li>
        <li style={{ listStyleType: 'decimal', fontSize: '10.5pt' }}>
          <strong>
            <span style={{ fontSize: '10.5pt' }}>CONDIÇÕES DE ENTREGA</span>
          </strong>

          <ol className={css.normalList}>
            <li style={{ listStyleType: 'decimal', fontSize: '10.5pt' }}>
              <span style={{ fontSize: '10.5pt' }}>
                Após a confirmação do pagamento, os PARCEIROS se comprometem a entregar o PRODUTO em
                perfeito estado, conforme a descrição e características previstas no MARKETPLACE e
                na localidade que o PARCEIRO comprador indicar no ato da compra, sendo que as
                informações cadastradas são de responsabilidade do PARCEIRO.&nbsp;
              </span>
            </li>
            <li style={{ listStyleType: 'decimal', fontSize: '10.5pt' }}>
              <span style={{ fontSize: '10.5pt' }}>
                Caso o PARCEIRO comprador solicite mais de um PRODUTO em seu pedido, o prazo de
                entrega poderá sofrer variações, em função da maior quantidade de unidades do
                PRODUTO, podendo haver prazos de entrega diferentes.&nbsp;
              </span>
            </li>
            <li style={{ listStyleType: 'decimal', fontSize: '10.5pt' }}>
              <span style={{ fontSize: '10.5pt' }}>
                O PARCEIRO comprador está ciente de que o prazo de entrega informado durante o
                procedimento de compra do PRODUTO leva em consideração o estoque do PARCEIRO
                vendedor, a região da entrega, o processo de emissão da Nota Fiscal, o tempo de
                disponibilização do PRODUTO e sua origem (se importado ou nacional).
              </span>
            </li>
            <li style={{ listStyleType: 'decimal', fontSize: '10.5pt' }}>
              <span style={{ fontSize: '10.5pt' }}>
                O PARCEIRO comprador poderá cancelar seu pedido de acordo com a negociação direta
                realizada com o PARCEIRO vendedor, desde que a Nota Fiscal ainda não tenha sido
                emitida. Após a emissão da Nota Fiscal, se o PARCEIRO comprador não mais desejar
                receber o pedido, deve entrar em contato com o PARCEIRO vendedor para formalizar a
                solicitação de cancelamento.
              </span>
            </li>
            <li style={{ listStyleType: 'decimal', fontSize: '10.5pt' }}>
              <span style={{ fontSize: '10.5pt' }}>
                Caso o PRODUTO tenha saído do estoque do PARCEIRO vendedor dentro do prazo acordado
                e informado ao PARCEIRO comprador, porém não tenha sido possível a entrega do mesmo
                em razão de fatos decorrentes de caso fortuito ou força maior (como por exemplo
                greves, manifestações, acidentes climáticos, entre outros) ou em razão de fatos
                atribuíveis ao próprio PARCEIRO (como, por exemplo, em razão da ausência do PARCEIRO
                ou pessoa autorizada no local de entrega, recusa por terceiros do recebimento da
                entrega, entre outros), a HUSQVARNA e/ou o(s) PARCEIRO(S) não poderão ser
                responsabilizados.&nbsp;
              </span>
            </li>
            <li style={{ listStyleType: 'decimal', fontSize: '10.5pt' }}>
              <span style={{ fontSize: '10.5pt' }}>
                Considera-se efetuada cada entrega a partir do momento no qual a transportadora
                coloca o PRODUTO à disposição do PARCEIRO comprador, o que se comprova por meio do
                sistema de controle utilizado pela transportadora e/ou prova inequívoca de entrega..
              </span>
            </li>
            <li style={{ listStyleType: 'decimal', fontSize: '10.5pt' }}>
              <span style={{ fontSize: '10.5pt' }}>
                A HUSQVARNA e o PARCEIRO não autoriza a transportadora a:&nbsp;
              </span>

              <ol>
                <li style={{ listStyleType: 'lower-roman', fontSize: '10.5pt' }}>
                  <span style={{ fontSize: '10.5pt' }}>Entrar no domicílio da entrega;&nbsp;</span>
                </li>
                <li style={{ listStyleType: 'lower-roman', fontSize: '10.5pt' }}>
                  <span style={{ fontSize: '10.5pt' }}>Entregar por meios alternativos;&nbsp;</span>
                </li>
                <li style={{ listStyleType: 'lower-roman', fontSize: '10.5pt' }}>
                  <span style={{ fontSize: '10.5pt' }}>
                    Abrir ou violar a embalagem do produto;&nbsp;
                  </span>
                </li>
                <li style={{ listStyleType: 'lower-roman', fontSize: '10.5pt' }}>
                  <span style={{ fontSize: '10.5pt' }}>
                    Realizar entrega em endereço diferente do que consta no DANFE;
                  </span>
                </li>
                <li style={{ listStyleType: 'lower-roman', fontSize: '10.5pt' }}>
                  <span style={{ fontSize: '10.5pt' }}>
                    Realizar entrega a menor de idade ou sem documento de identificação;&nbsp;
                  </span>
                </li>
                <li style={{ listStyleType: 'lower-roman', fontSize: '10.5pt' }}>
                  <span style={{ fontSize: '10.5pt' }}>
                    Testar ou realizar qualquer tarefa que implique em manuseio ou aplicação dos
                    produtos entregues.
                  </span>
                </li>
              </ol>
            </li>
          </ol>
        </li>
        <li style={{ listStyleType: 'decimal', fontSize: '10.5pt' }}>
          <strong>
            <span style={{ fontSize: '10.5pt' }}>DIREITO DE ARREPENDIMENTO</span>
          </strong>

          <ol className={css.normalList}>
            <li style={{ listStyleType: 'decimal', fontSize: '10.5pt' }}>
              <span style={{ fontSize: '10.5pt' }}>
                Nas situações aplicáveis, será facultado ao PARCEIRO comprador o exercício do
                direito de arrependimento da compra, sem necessidade de o PARCEIRO externar um
                motivo pelo arrependimento, hipótese em que deverão ser observadas as seguintes
                condições:
              </span>
              <ol>
                <li style={{ listStyleType: 'lower-roman', fontSize: '10.5pt' }}>
                  <span style={{ fontSize: '10.5pt' }}>
                    O prazo para manifestar o arrependimento pela compra do PRODUTO é de até 7
                    (sete) dias corridos, a contar da data do seu recebimento;
                  </span>
                </li>
                <li style={{ listStyleType: 'lower-roman', fontSize: '10.5pt' }}>
                  <span style={{ fontSize: '10.5pt' }}>
                    A manifestação pelo arrependimento deverá ser realizada se utilizando dos
                    contatos oferecidos pelo PARCEIRO vendedor no momento da compra.
                  </span>
                </li>
                <li style={{ listStyleType: 'lower-roman', fontSize: '10.5pt' }}>
                  <span style={{ fontSize: '10.5pt' }}>
                    ser encaminhado devidamente embalado, sem indícios de uso, sem violação do lacre
                    original do fabricante, acompanhado da primeira via da Nota Fiscal, manual e
                    todos os seus acessórios.
                  </span>
                </li>
              </ol>
            </li>
          </ol>
        </li>
        <li style={{ listStyleType: 'decimal', fontSize: '10.5pt' }}>
          <strong>
            <span style={{ fontSize: '10.5pt' }}>
              TROCAS E DEVOLUÇÕES EM CASOS DE NÃO CONFORMIDADE
            </span>
          </strong>

          <ol className={css.normalList}>
            <li style={{ listStyleType: 'decimal', fontSize: '10.5pt' }}>
              <span style={{ fontSize: '10.5pt' }}>
                O PARCEIRO comprador deverá entrar em contato com o PARCEIRO vendedor que adquiriu o
                produto, conforme item acima, tão logo tenha conhecimento de algum defeito e/ou
                vício nos PRODUTOS adquiridos no MARKETPLACE.&nbsp;
              </span>
            </li>
            <li style={{ listStyleType: 'decimal', fontSize: '10.5pt' }}>
              <span style={{ fontSize: '10.5pt' }}>
                O PRODUTO com defeito deverá ser encaminhado, mediante autorização prévia e por
                escrito, para o endereço indicado pelo PARCEIRO vendedor, acompanhado dos manuais,
                dos acessórios e da primeira via da Nota Fiscal de venda (para que seja possível
                realizar a identificação do PRODUTO). Caso o PARCEIRO comprador não possua este
                documento, não será aceita a solicitação de troca. Em caso afirmativo, providenciará
                a restituição do valor pago ou troca do PRODUTO de acordo com a solicitação feita
                pelo PARCEIRO comprador.
              </span>
            </li>
            <li style={{ listStyleType: 'decimal', fontSize: '10.5pt' }}>
              <span style={{ fontSize: '10.5pt' }}>
                Caso não haja possibilidade de conserto do PRODUTO, o PARCEIRO comprador poderá
                optar por outro PRODUTO de mesmo valor, pela devolução do dinheiro, ou pelo PRODUTO
                escolhido, com o abatimento proporcional do preço.
              </span>
            </li>
            <li style={{ listStyleType: 'decimal', fontSize: '10.5pt' }}>
              <span style={{ fontSize: '10.5pt' }}>
                O prazo de ressarcimento dos valores depende da administradora do cartão, não
                possuindo a HUSQVARNA qualquer interferência e/ou responsabilidade neste prazo.
              </span>
            </li>
          </ol>
        </li>
        <li style={{ listStyleType: 'decimal', fontSize: '10.5pt' }}>
          <strong>
            <span style={{ fontSize: '10.5pt' }}>VIGÊNCIA E RESCISÃO</span>
          </strong>

          <ol className={css.normalList}>
            <li style={{ listStyleType: 'decimal', fontSize: '10.5pt' }}>
              <span style={{ fontSize: '10.5pt' }}>
                Estes TERMOS DE USO entram em vigor no momento de sua publicação no MARKETPLACE,
                permanecendo vigente por todo o período de todas as vezes que o PARCEIRO utiliza ou
                navega no MARKETPLACE.
              </span>
            </li>
            <li style={{ listStyleType: 'decimal', fontSize: '10.5pt' }}>
              <span style={{ fontSize: '10.5pt' }}>
                O PARCEIRO poderá se descadastrar do MARKETPLACE a qualquer tempo, a seu exclusivo
                critério, caso em que, porém, algumas funcionalidades do MARKETPLACE podem ficar
                indisponíveis, como efetuar compras. &nbsp;
              </span>
            </li>
            <li style={{ listStyleType: 'decimal', fontSize: '10.5pt' }}>
              <span style={{ fontSize: '10.5pt' }}>
                A HUSQVARNA reserva-se o direito de excluir qualquer PARCEIRO, sem assumir nenhum
                ônus, se julgar necessário ou se o PARCEIRO violar qualquer um dos termos e
                condições estabelecidos aqui ou em lei. Os PARCEIROS que fornecerem dados falsos ou
                de terceiros sem o seu consentimento também serão excluídos.&nbsp;
              </span>
            </li>
            <li style={{ listStyleType: 'decimal', fontSize: '10.5pt' }}>
              <span style={{ fontSize: '10.5pt' }}>
                Alguns itens aqui previstos, devido à sua natureza, tais como responsabilidades,
                sobreviverão à extinção destes TERMOS DE USO.
              </span>
            </li>
          </ol>
        </li>
        <li style={{ listStyleType: 'decimal', fontSize: '10.5pt' }}>
          <strong>
            <span style={{ fontSize: '10.5pt' }}>DISPOSIÇÕES GERAIS</span>
          </strong>

          <ol className={css.normalList}>
            <li style={{ listStyleType: 'decimal', fontSize: '10.5pt' }}>
              <span style={{ fontSize: '10.5pt' }}>
                A responsabilidade total do PARCEIRO com relação a qualquer inconformidade do
                PRODUTO será limitada à obrigação devolução de valores, sendo certo que nenhuma
                indenização ou reparação será devida pelo PARCEIRO por qualquer outro prejuízo que o
                outro PARCEIRO possa ter com o recebimento ou a utilização do PRODUTO.
              </span>
            </li>
            <li style={{ listStyleType: 'decimal', fontSize: '10.5pt' }}>
              <span style={{ fontSize: '10.5pt' }}>
                Os PRODUTOS, informações, valores, serviços sistemas e/ou software, publicados no
                MARKETPLACE podem conter imprecisões e/ou erros de grafia, razão pela qual a
                HUSQVARNA E O PARCEIRO envidará seus melhores esforços para manter o MARKETPLACE
                atualizado periodicamente.
              </span>
            </li>
            <li style={{ listStyleType: 'decimal', fontSize: '10.5pt' }}>
              <span style={{ fontSize: '10.5pt' }}>
                A Declaração de Privacidade do MARKETPLACE é aplicável ao PARCEIRO, quando este
                acessar o MARKETPLACE e pode ser encontrada no seguinte link:&nbsp;
              </span>
              <a href="https://respare-br.husqvarna.com/pt-BR/infos/privacy">
                <u>
                  <span style={{ color: '#0563c1', fontSize: '10.5pt' }}>
                    https://respare-br.husqvarna.com/pt-BR/infos/privacy
                  </span>
                </u>
              </a>
              <span style={{ fontSize: '10.5pt' }}>.&nbsp;</span>
            </li>
            <li style={{ listStyleType: 'decimal', fontSize: '10.5pt' }}>
              <span style={{ fontSize: '10.5pt' }}>
                A HUSQVARNA poderá alterar, modificar e/ou ajustar estes TERMOS DE USO a qualquer
                tempo, sem necessidade de aviso prévio aos PARCEIROS, sendo que essas alterações,
                modificações e/ou ajustes terão efeito a partir do momento em que se tornarem
                públicos. Desse modo, o PARCEIRO obriga-se a rever os presentes TERMOS DE USO
                periodicamente, restando claro que o PARCEIRO se subordina à aceitação dos TERMOS DE
                USO vigente no momento de seu acesso ao MARKETPLACE. Caso o PARCEIRO não concorde
                com as alterações dos TERMOS DE USO, deverá cancelar sua conta e cessar toda e
                qualquer utilização do MARKETPLACE. O fato de o PARCEIRO continuar a acessar ou usar
                o MARKETPLACE após a modificação dos TERMOS DE USO representa seu consentimento em
                vincular-se aos TERMOS alterados.
              </span>
            </li>
            <li style={{ listStyleType: 'decimal', fontSize: '10.5pt' }}>
              <span style={{ fontSize: '10.5pt' }}>
                Fica eleito o foro da Comarca de São Paulo, Estado de São Paulo, como competente
                para dirimir quaisquer controvérsias decorrentes destes TERMOS DE USO,
                independentemente de qualquer outro, por mais privilegiado que seja ou venha a ser.
              </span>
            </li>
          </ol>
        </li>
      </ol>
    </div>
  );
};

export default TermsContentBrazil;
